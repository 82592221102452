import React, { Component } from "react";

const colors = [
  "#9400D3",
  "#4B0082",
  "#0000FF",
  "#00FF00",
  //"#FFFF00",
  "#FF7F00",
  "#FF0000",
];
const colorsLength = colors.length - 1;

class Rainbow extends Component {
  constructor(props) {
    super(props);
    this.state = { text: props.text, currentIndex: 0 };
  }

  componentDidMount() {
    this.interval = setInterval(
      () =>
        this.setState({
          ...this.state,
          currentIndex:
            this.state.currentIndex == this.state.text.length - 1
              ? 0
              : this.state.currentIndex + 1,
        }),
      300
    );
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  render() {
    let counter = -1;
    return (
      <div>
        {[...this.state.text].map((char) => {
          if (char !== " ") {
            counter++;
          }

          return (
            <span
              className="transition-colors duration-300"
              style={{
                color:
                  colors[(this.state.currentIndex + counter) % colorsLength],
              }}
            >
              {char}
            </span>
          );
        })}
      </div>
    );
  }
}

export default Rainbow;
