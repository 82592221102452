import logo from "./logo.svg";
import "./App.css";
import Rainbow from "./components/Rainbow";

function App() {
  return (
    <div className="App">
      <h1 className="text-5xl md:text-6xl text-center">
        <Rainbow text="welcome to jenny booking" />
      </h1>
      <p className="flex gap-x-2 justify-center text-lg md:text-3xl">
        <img
          style={{ height: "2rem" }}
          src="https://upload.wikimedia.org/wikipedia/commons/4/45/CG_Heart_%28cropped%29.gif"
        />
        <div>i can be your jenny </div>
        <img
          style={{ height: "2rem" }}
          src="https://upload.wikimedia.org/wikipedia/commons/4/45/CG_Heart_%28cropped%29.gif"
        />
      </p>
      <div
        className="p-8 px-20 bg-red-600 text-white my-8 text-3xl md:text-4xl -rotate-3 text-center"
        style={{ width: "calc(100% + 10rem)" }}
      >
        hire jenny b4 ur neiburgh (sp?) 2DAY!
      </div>
      <div className="relative flex flex-col md:block mx-auto">
        <div className="text-center">
          <b className="text-lg">hire jenny today !</b>
          <br />
          jenny comes in many forms such as:
        </div>
        <div className="flex justify-center">
          <ul className="list-disc">
            <li>jenny</li>
            <li>dog</li>
            <li>stupid dog</li>
            <li>jenny dog</li>
          </ul>
        </div>

        <img
          src="/dog.jpeg"
          className="mt-4 md:mt-0 md:absolute top-0 -right-96"
        />
      </div>

      <div className="mt-4">
        <div>jenny offers many services such as:</div>
        <div className="flex justify-center">
          <ul className="list-disc">
            <li>fuck you UP</li>
            <li>get the fucked UP</li>
            <li>fuck UP</li>
            <li>gardening</li>
          </ul>
        </div>
      </div>

      <div className="text-center">
        <p>
          jenny rates starts at $$ one million dollars $$ and dog costs extra
          but stupid costs less
        </p>

        <p className="mt-2 md:mt-0">
          jenny does <b>NOT</b> offer help with math ! i hate math and so should
          u{" "}
          <a href="/math.png" className="font-semibold underline">
            (proof)
          </a>
        </p>
      </div>

      <div className="text-center">
        contact jenny homie dude bro and happy birthday
      </div>

      <div>T O D A Y</div>

      <div className="text-center">
        <p>
          disclaimer : in case jenny comes in contact with a lizard and gets
          bitten, jenny WILL turn into a werewolf
        </p>
        <p className="mt-2 md:mt-0">
          do not be scared ! jenny is vegan{" "}
          <a
            onClick={() => alert("u r on ur own sorry about it im a werewolf")}
            className="font-semibold underline cursor-pointer"
          >
            (please read terms and cold dishes by clicking here)
          </a>
        </p>
      </div>

      <div>
        <a href="https://www.instagram.com/jenny_thalia/">
          <img
            className="w-8"
            src="https://upload.wikimedia.org/wikipedia/commons/thumb/e/e7/Instagram_logo_2016.svg/264px-Instagram_logo_2016.svg.png?20210403190622"
          />
        </a>
      </div>
    </div>
  );
}

export default App;
